(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"index_atlas_1", frames: [[956,0,46,75],[803,48,151,46],[556,96,229,46],[556,48,245,46],[783,144,165,46],[556,144,225,46],[556,0,253,46],[787,96,154,46],[943,96,107,46],[0,212,1915,40],[0,0,554,210]]},
		{name:"index_atlas_2", frames: [[0,0,1920,1000],[0,1002,1920,1000]]}
];


(lib.AnMovieClip = function(){
	this.actionFrames = [];
	this.ignorePause = false;
	this.gotoAndPlay = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
	}
	this.play = function(){
		cjs.MovieClip.prototype.play.call(this);
	}
	this.gotoAndStop = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
	}
	this.stop = function(){
		cjs.MovieClip.prototype.stop.call(this);
	}
}).prototype = p = new cjs.MovieClip();
// symbols:



(lib.CachedBmp_10 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_9 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_8 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_7 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_6 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_5 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_4 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_3 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(7);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_2 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(8);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_1 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(9);
}).prototype = p = new cjs.Sprite();



(lib.bgCapa = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.bgImplantacoes = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.logoSeven = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(10);
}).prototype = p = new cjs.Sprite();
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.sign_out_text = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutText
	this.instance = new lib.CachedBmp_10();
	this.instance.setTransform(0,0,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_text, new cjs.Rectangle(0,0,23,37.5), null);


(lib.sign_out_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutBackground
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#990000").beginStroke().moveTo(-20,30).lineTo(-20,-30).lineTo(20,-30).lineTo(20,30).closePath();
	this.shape.setTransform(20,30);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_background, new cjs.Rectangle(0,0,40,60), null);


(lib.pseudo_after = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// MenuPseudoAfter
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(134,126,109,0.498)").beginStroke().moveTo(-68.6,25).lineTo(-68.6,-25).lineTo(68.6,-25).lineTo(68.6,25).closePath();
	this.shape.setTransform(68.6,25);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.pseudo_after, new cjs.Rectangle(0,0,137.2,50), null);


(lib.menu_line = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// line
	this.shape = new cjs.Shape();
	this.shape.graphics.beginLinearGradientFill(["#5BC3BE","#1F4F6E"],[0,1],-680,0,680,0).beginStroke().moveTo(-680,1).lineTo(-680,-1).lineTo(680,-1).lineTo(680,1).closePath();
	this.shape.setTransform(680,1);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_line, new cjs.Rectangle(0,0,1360,2), null);


(lib.logo_small = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo
	this.instance = new lib.logoSeven();
	this.instance.setTransform(0,0,0.352,0.352);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_small, new cjs.Rectangle(0,0,195,73.9), null);


(lib.logo_large = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo
	this.instance = new lib.logoSeven();
	this.instance.setTransform(19.7,9.7,0.2887,0.2888);

	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#012B3E").beginStroke().moveTo(-100,40).lineTo(-100,-40).lineTo(100,-40).lineTo(100,40).closePath();
	this.shape.setTransform(99.65,40);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_large, new cjs.Rectangle(-0.3,0,200,80), null);


(lib.botoes = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-236.1,78).lineTo(-236.1,-78).lineTo(236.1,-78).lineTo(236.1,78).closePath();
	this.shape.setTransform(236.05,78.025);
	this.shape._off = true;

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(3).to({_off:false},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,472.1,156.1);


(lib.background_dynamic = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {capa:0,institucional:1,produto:2,localizacao:3,diferenciais:4,implantacoes:5,plantas:6,imagens:7};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(8));

	// background
	this.instance = new lib.bgImplantacoes();
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(5).to({_off:false},0).to({_off:true},1).wait(2));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1920,1000);


(lib.background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// background
	this.instance = new lib.bgCapa();

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.background, new cjs.Rectangle(0,0,1920,1000), null);


(lib.arrow = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// arrow
	this.shape = new cjs.Shape();
	this.shape.graphics.beginLinearGradientFill(["#5BC3BE","#FFFFFF","#5BC3BE","#1F4F6E"],[0,0,0,1],-24,0,24,0).beginStroke().moveTo(-24,12).lineTo(0,-12).lineTo(24,12).closePath();

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-12,48,24);


(lib.menu_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// menu_line
	this.instance = new lib.menu_line();
	this.instance.setTransform(1240,-79,1,1,0,0,0,680,1);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// background
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#867E6D").beginStroke().moveTo(-960,40).lineTo(-960,-40).lineTo(960,-40).lineTo(960,40).closePath();
	this.shape.setTransform(960,-40);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_background, new cjs.Rectangle(0,-80,1920,80), null);


(lib.arrow_up = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_9 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(9).call(this.frame_9).wait(1));

	// arrow
	this.instance = new lib.arrow("synched",0);
	this.instance.setTransform(0,12);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({y:-26},5).to({y:-12},2).to({x:0.3,y:-18.4},2).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-38,48.3,62);


(lib.menu_marker = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {"capa":0,"institucional":1,"produto":2,"localizacao":3,"diferenciais":4,"implantacoes":5,"plantas":6,"imagens":7};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(8));

	// arrow_up
	this.occ_arrow_up = new lib.arrow_up();
	this.occ_arrow_up.name = "occ_arrow_up";
	this.occ_arrow_up.setTransform(205.7,0);

	this.timeline.addTween(cjs.Tween.get(this.occ_arrow_up).wait(1).to({x:347.25},0).wait(1).to({x:503.4},0).wait(1).to({x:655.4},0).wait(1).to({x:822.45},0).wait(1).to({x:995.5},0).wait(1).to({x:1150.15},0).wait(1).to({x:1280.15},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(181.7,0,1122.5,24);


// stage content:
(lib.index = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.actionFrames = [0];
	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const AllTap = window.AllTap;
		
		/**
		 *
		 *  @param object
		 *  Set "this", object of canvas CreateJs.
		 */
		AllTap.setState({...AllTap.state, _this_index: this});
		
		/**
		 *
		 * @param array
		 *  [{
		 *      name: {string} = Name of occurrence not prefixed here "btn_".
		 *      occ_logo_large: {boolean} = Is visible (optional).
		 *      occ_logo_small: {boolean} = Is visible (optional).
		 *      occ_background_effect: {boolean} = Is visible (optional).
		 *      occ_video: {boolean} = Is button video, run outside the iFrame (optional).
		 *      occ_tour: {boolean} = Is button tour, run outside the iFrame (optional).
		 *      page_default: {boolean} = Define function $page_default() "root", finish of video (optional).
		 *  }]
		 */
		AllTap.createMenu([
		    {
		        name: 'capa',
		        occ_logo_large: false,
		        occ_logo_small: false,
		        occ_background: true,
		        page_default: true
		    },
		    {
		        name: 'institucional',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'produto',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'localizacao',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'diferenciais',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'implantacoes',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'plantas',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'imagens',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    }
		]);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// LogoLarge
	this.occ_logo_large = new lib.logo_large();
	this.occ_logo_large.name = "occ_logo_large";
	this.occ_logo_large.setTransform(97.35,1000.1,1,0.9999,0,0,0,0,0.1);
	this.occ_logo_large.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_large).wait(1));

	// LogoSmall
	this.occ_logo_small = new lib.logo_small();
	this.occ_logo_small.name = "occ_logo_small";
	this.occ_logo_small.setTransform(209.9,1013.45,0.7194,0.7189);
	this.occ_logo_small.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_small).wait(1));

	// SignOutButton
	this.btn_sign_out = new lib.botoes();
	this.btn_sign_out.name = "btn_sign_out";
	this.btn_sign_out.setTransform(1870,1000,0.1059,0.5127);
	this.btn_sign_out.visible = false;
	new cjs.ButtonHelper(this.btn_sign_out, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_sign_out).wait(1));

	// sign_out_text
	this.instance = new lib.sign_out_text();
	this.instance.setTransform(1900,1040,1,1,0,0,0,13.2,18.7);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// sign_out_background
	this.instance_1 = new lib.sign_out_background();
	this.instance_1.setTransform(1900,1040,1,1,0,0,0,20,30);
	this.instance_1.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

	// MenuButton
	this.btn_diferenciais = new lib.botoes();
	this.btn_diferenciais.name = "btn_diferenciais";
	this.btn_diferenciais.setTransform(967.5,1010,0.2804,0.3844,0,0,0,0.6,0);
	new cjs.ButtonHelper(this.btn_diferenciais, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_capa = new lib.botoes();
	this.btn_capa.name = "btn_capa";
	this.btn_capa.setTransform(380.3,1010,0.1551,0.3844,0,0,0,0.7,0);
	new cjs.ButtonHelper(this.btn_capa, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_produto = new lib.botoes();
	this.btn_produto.name = "btn_produto";
	this.btn_produto.setTransform(663.4,1010,0.2169,0.3846,0,0,0,0.5,0);
	new cjs.ButtonHelper(this.btn_produto, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_institucional = new lib.botoes();
	this.btn_institucional.name = "btn_institucional";
	this.btn_institucional.setTransform(487.2,1010,0.3017,0.3846,0,0,0,0.1,0);
	new cjs.ButtonHelper(this.btn_institucional, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_plantas = new lib.botoes();
	this.btn_plantas.name = "btn_plantas";
	this.btn_plantas.setTransform(1313.6,1010,0.2023,0.3846,0,0,0,0.5,0);
	new cjs.ButtonHelper(this.btn_plantas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_imagens = new lib.botoes();
	this.btn_imagens.name = "btn_imagens";
	this.btn_imagens.setTransform(1442.8,1010,0.2057,0.3844,0,0,0,0.5,0);
	new cjs.ButtonHelper(this.btn_imagens, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_implantacoes = new lib.botoes();
	this.btn_implantacoes.name = "btn_implantacoes";
	this.btn_implantacoes.setTransform(1133.55,1010,0.31,0.3844,0,0,0,0.3,0);
	new cjs.ButtonHelper(this.btn_implantacoes, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_localizacao = new lib.botoes();
	this.btn_localizacao.name = "btn_localizacao";
	this.btn_localizacao.setTransform(799.55,1010,0.2844,0.3844,0,0,0,0.6,0);
	new cjs.ButtonHelper(this.btn_localizacao, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.btn_localizacao},{t:this.btn_implantacoes},{t:this.btn_imagens},{t:this.btn_plantas},{t:this.btn_institucional},{t:this.btn_produto},{t:this.btn_capa},{t:this.btn_diferenciais}]}).wait(1));

	// MenuPseudoAfter
	this.instance_2 = new lib.pseudo_after();
	this.instance_2.setTransform(968.15,1015,0.965,1,0,0,0,0.2,0);
	this.instance_2.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

	// MenuText
	this.instance_3 = new lib.CachedBmp_9();
	this.instance_3.setTransform(1323.45,1028.5,0.5,0.5);

	this.instance_4 = new lib.CachedBmp_8();
	this.instance_4.setTransform(809.35,1028.5,0.5,0.5);

	this.instance_5 = new lib.CachedBmp_7();
	this.instance_5.setTransform(497.15,1028.5,0.5,0.5);

	this.instance_6 = new lib.CachedBmp_6();
	this.instance_6.setTransform(673.3,1028.5,0.5,0.5);

	this.instance_7 = new lib.CachedBmp_5();
	this.instance_7.setTransform(977.35,1028.5,0.5,0.5);

	this.instance_8 = new lib.CachedBmp_4();
	this.instance_8.setTransform(1143.45,1028.5,0.5,0.5);

	this.instance_9 = new lib.CachedBmp_3();
	this.instance_9.setTransform(1452.7,1028.5,0.5,0.5);

	this.instance_10 = new lib.CachedBmp_2();
	this.instance_10.setTransform(390.15,1028.5,0.5,0.5);

	this.instance_11 = new lib.CachedBmp_1();
	this.instance_11.setTransform(469.3,1030,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3}]}).wait(1));

	// MenuMarker
	this.occ_menu_marker = new lib.menu_marker();
	this.occ_menu_marker.name = "occ_menu_marker";
	this.occ_menu_marker.setTransform(211.1,1080);
	this.occ_menu_marker.shadow = new cjs.Shadow("rgba(0,0,0,1)",3,3,4);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_marker).wait(1));

	// MenuBackground
	this.occ_menu_background = new lib.menu_background();
	this.occ_menu_background.name = "occ_menu_background";
	this.occ_menu_background.setTransform(0,1080);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_background).wait(1));

	// background
	this.occ_background = new lib.background();
	this.occ_background.name = "occ_background";
	this.occ_background.setTransform(960,500,1,1,0,0,0,960,500);

	this.timeline.addTween(cjs.Tween.get(this.occ_background).wait(1));

	// BackgroundDynamic
	this.occ_background_dynamic = new lib.background_dynamic();
	this.occ_background_dynamic.name = "occ_background_dynamic";
	this.occ_background_dynamic.setTransform(960,500,1,1,0,0,0,960,500);

	this.timeline.addTween(cjs.Tween.get(this.occ_background_dynamic).wait(1));

	this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(960,540,960,575);
// library properties:
lib.properties = {
	id: '5F0EC95E53C3814DAF2FA395200D5FE6',
	width: 1920,
	height: 1080,
	fps: 30,
	color: "#FFFFFF",
	opacity: 0.00,
	manifest: [
		{src:"images/index_atlas_1.png", id:"index_atlas_1"},
		{src:"images/index_atlas_2.png", id:"index_atlas_2"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['5F0EC95E53C3814DAF2FA395200D5FE6'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}
an.handleSoundStreamOnTick = function(event) {
	if(!event.paused){
		var stageChild = stage.getChildAt(0);
		if(!stageChild.paused || stageChild.ignorePause){
			stageChild.syncStreamSounds();
		}
	}
}
an.handleFilterCache = function(event) {
	if(!event.paused){
		var target = event.target;
		if(target){
			if(target.filterCacheList){
				for(var index = 0; index < target.filterCacheList.length ; index++){
					var cacheInst = target.filterCacheList[index];
					if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
						cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
					}
				}
			}
		}
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;